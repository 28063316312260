import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useStateValue } from "../../stateProvider/stateProvider";
import { SET_ROLES,LOGOUT } from "../../constans/reducerActions";
import Toast from "../Toast/Toast";

import "./Login.css";

const Login = ({ server }) => {
  const [reducer, dispatch] = useStateValue();
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [logging, setLogging] = useState(false);
  const [infoType,setInfoType] = useState("");
  const [info,setInfo] = useState("")

  const nav = useNavigate();

  const handleChange = (type, e) => {
    if (type === "login") setLogin(e.target.value);
    if (type === "password") setPass(e.target.value);
  };

  const logiIn = async () => {
    try {
      setLogging(true);
      const json = await fetch(server + "/api/doppio/user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login,
          password: pass,
        }),
      });

  
      const data = await json.json();
      if(!data.token){
        setInfo("Błąd logowania, spróbuj ponownie!")
        setInfoType("Fail")
        dispatch({ type: LOGOUT,})
        setLogin("");
        setPass("");
        setLogging(false);
      } 
      else {
        localStorage.setItem("SLIDESHOW-TOKEN", data.token);
        localStorage.setItem("SLIDESHOW-ROLES", data.roles);
        nav("/Choose")
  
        dispatch({ type: SET_ROLES, item: { roles: data.roles } });
        setLogging(false);
  
        window.location.reload(false)
      }

     
    } catch (err) {
      setInfoType("Fail")
      setInfo("Błąd w czasie logowania!")
      dispatch({ type: LOGOUT,})
      nav('/login')
    }
    setLogin("");
    setPass("");
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (!login || !pass) 
        return alert("Nie podano wszystkich danych");

    logiIn();
  };



  return (
    <div className="login-main">
          {
      infoType &&         
      <Toast  
          infoType={infoType}
          info={info}
          setInfoType={setInfoType}
      />
    }

      <div className="login__form-wrap">
        <form className="login-form">
          {logging && <h3> Logowanie...</h3>}
          <img
            src="https://i.ibb.co/qJRMc79/Doppio.png"
            className="login-form__logo"
          />
          <label className="login-form__label-txt" htmlFor="login">
            Login:{" "}
          </label>
          <input
            className="medium-input login-form__input"
            type="text"
            name="login"
            value={login}
            onChange={(e) => handleChange("login", e)}
          />

          <label className=" login-form__label-txt" htmlFor="password">
            Hasło:{" "}
          </label>
          <input
            className="medium-input login-form__input"
            type="password"
            name="password"
            value={pass}
            onChange={(e) => handleChange("password", e)}
          />

          <button
            className="button thin-btn login-form__btn"
            type="submit"
            onClick={handleClick}
          >
            Zaloguj
          </button>
        </form>
      </div>
      <div className="login-bg">
        <img
          src="./Images/paski.png"
          className="login-bg-img"
        />
        <div className="login-bg-txt">
          <h1 className="">Dotrzyj z właściwym</h1>
          <p className="">komunikatem do właściwych ludzi</p>
        </div>
      </div>
    </div>
  );
};
export default Login;
