import React from 'react';
import './index.css';
import App from './App';
import Login from './elements/Login/Login';
import Edit from './elements/Edit/Edit';
import Error_404 from './elements/Error_404/err';
import { ChooseTable } from './elements/ChooseTable/ChooseTable';
import Add from './elements/Add/add';
import { GUEST,USER,ADMIN } from './constans/roles';
import './Componets.css'
import { HashRouter as BrowserRouter, Routes,Route,Switch } from 'react-router-dom';
import { useStateValue } from './stateProvider/stateProvider';
import { SET_ROLES } from './constans/reducerActions';
import { useEffect } from 'react';

const server = 'https://panel-preview.herokuapp.com'
//const server = 'http://localhost:5000';

const Content = () => {  
  const [reducer,dispatch] = useStateValue();

    function checkAcces(type = GUEST){
      if(!localStorage.getItem("SLIDESHOW-TOKEN")) {
          localStorage.setItem('SLIDESHOW-TOKEN', "")
          localStorage.setItem('SLIDESHOW-ROLES', "")
        return false
      }

        if(type === USER || type === ADMIN)
          if(localStorage.getItem("SLIDESHOW-ROLES").split(',').indexOf(USER) < 0)
            return false;
          
        else if(type === ADMIN)
            if(localStorage.getItem("SLIDESHOW-ROLES").split(',').indexOf(ADMIN) < 0)
              return false;
      

      return true
  }
  
  /////////////////////////////////
  useEffect(() => {
    if(!localStorage.getItem("SLIDESHOW-TOKEN")) {
      localStorage.setItem('SLIDESHOW-TOKEN', "")
      localStorage.setItem('SLIDESHOW-ROLES', "")
    return false
  } else 
    dispatch({type: SET_ROLES, item: {roles: localStorage.getItem('SLIDESHOW-ROLES').split(',')} })
    //////////////////////////////////////////
  },[])

    return (
        <BrowserRouter>
        <Routes>
          <Route path="/" exact element={checkAcces() ? <App server={server}  /> : <Login server={server} />} />
          <Route path="/login" element={<Login server={server} />} />
          <Route path="/Choose" element={checkAcces() ? <ChooseTable server={server}  /> : <Login server={server} />} />
          <Route path="/*"element={<Error_404 />} />
          
        </Routes>
      </BrowserRouter>
    )
}

export default Content