import './Header.css'
import { useNavigate} from 'react-router-dom'
import { useStateValue } from '../../stateProvider/stateProvider';
import { GUEST,USER,ADMIN } from '../../constans/roles';
import { LOGOUT, SET_CHOOSEN_TABLE } from '../../constans/reducerActions';
import { WIZUALIZACJE,ZAKODOWANE } from '../../constans/tableCalls';
import { getDefaultNormalizer } from '@testing-library/react';
import { useEffect, useState } from 'react';

const Header = ({getData,server,name,img}) => {
    const navigate = useNavigate();
    const [{role,choosenTable},dispatch] = useStateValue()

    const logOut = () => {
        dispatch({type:LOGOUT})
        navigate('/login')
    }

    const changeTable = () => {
        let chosen;

        if(choosenTable === WIZUALIZACJE)
            chosen = ZAKODOWANE
        else 
            chosen = WIZUALIZACJE;

        dispatch({
            type:SET_CHOOSEN_TABLE,
            item: chosen
        })

        
    }


    return (
        <header className='header'>
            <img src="https://i.ibb.co/qJRMc79/Doppio.png" className='header__logo' />
            
            <div onClick={changeTable} className="header__change-table">
                {(choosenTable === WIZUALIZACJE) ? "Przejdź do kreacji zakodowanych" : "Przejdź do wizualiazcji statycznych" }
            </div>
            <div className='header__user-info'>
                <h3 className='header__user-role'>
                    {name ? name : ""}
                </h3>
                <img src={img} className='header__user-icon' />
                <button className='header-btn' onClick={logOut}>Wyloguj się</button>
            </div>
        </header>
    )
}
export default Header