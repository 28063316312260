import "./App.css";

import Creation, {CreationSchema} from "./elements/Creation/Creation";
import {Filters} from "./elements/Filters/Filters";
import {useEffect,useState} from 'react'
import Header from "./elements/Header/Header";
import { FilterPages } from "./elements/Filter/Filter";
import { useStateValue } from "./stateProvider/stateProvider";
import { defaultQuery } from "./reducer/init";
import { createQueryString } from "./Functions/queryFunctions";
import { USER,ADMIN } from "./constans/roles";
import {
  SET_ROLES,
  SET_QUERY,
  SET_ACTIVE_FILTERS,
  SET_ALL_PAGES,
  SET_CURRECT_PAGE,
  SET_FILTERS_DATA,
  SET_PAGGING_QUERY,
  LOGOUT} from './constans/reducerActions'
import { useNavigate } from "react-router";
import Add from "./elements/Add/add";
import Edit from "./elements/Edit/Edit";
import Toast from "./elements/Toast/Toast";

function App({server,roles}) {
    const [reducer,dispatch] = useStateValue();
    const [arr,setArr] = useState([])
    const [clearForm,setClearForm] = useState(true);
    const [name,setName] = useState('')
    const [img,setImg] = useState('')
    const nav = useNavigate()

    const [infoType,setInfoType] = useState("");
    const [info,setInfo] = useState("")
  
    const [scrollTop, setScrollTop] = useState(0);
    


    async function getFiltersData(){
      try{
      setArr(null)
      const json = await fetch(server + reducer.choosenTable +'/options',{
        headers: {
          'x-auth-token': localStorage.getItem('SLIDESHOW-TOKEN')
        }
      })

      const docs = await json.json();

      dispatch({type:SET_FILTERS_DATA, item: docs})
    } catch(err){
      console.error(err)
      setTimeout(() => {
        nav('/login')
      },2000)
      setInfoType("Fail")
      setInfo("Problem z sesją, nastąpi wylogowanie!")

      dispatch({ type: LOGOUT,})
    }
    }

    

    async function getData(){
      setArr(null)
      
      try{
      const json = await fetch(server + reducer.choosenTable,{
        headers: {
          'x-auth-token': localStorage.getItem('SLIDESHOW-TOKEN')
        }
      })
      const docs = await json.json()
      const data = docs.docs

      setClearForm(true)
      setArr(data)

      

      dispatch({ type: SET_ACTIVE_FILTERS, item: []})
      dispatch({ type: SET_PAGGING_QUERY, item: defaultQuery})
      dispatch({ type: SET_QUERY, item: defaultQuery})
      dispatch({ type: SET_CURRECT_PAGE, item: docs.page})
      dispatch({ type: SET_ALL_PAGES, item: docs.totalPages})

      } catch(err){
        console.error(err)
        setTimeout(() => {
        nav('/login')
      },2000)
      setInfoType("Fail")
      setInfo("Problem z sesją, nastąpi wylogowanie!")
      }


    }

    const handleClick = async (queryString,nQ) => {
      setArr(null)
      try{
      const json = await fetch(server+ reducer.choosenTable + '/get?' + queryString,{
        headers: {
          'x-auth-token': localStorage.getItem('SLIDESHOW-TOKEN')
        }
      })
      
      const docs = await json.json()

      const data = docs.docs
      let keys = {...nQ}

      setArr(data)

      dispatch({ type: SET_PAGGING_QUERY, item: nQ})
      dispatch({ type: SET_ACTIVE_FILTERS, item: keys})
      dispatch({ type: SET_CURRECT_PAGE, item: docs.page})
      dispatch({ type: SET_ALL_PAGES, item: docs.totalPages})
    } catch(err){
      setInfoType("Fail")
      setInfo("BŁĄD!")
    }
  } 


  const removeElement = async (id,item) => {
    if(window.confirm(`Czy na pewno chcesz usunąć ten rekord?
      Kampania: ${item.Kampania}
      Klient: ${item.Klient}
      Kategoria: ${item.Kategoria}
      Format: ${item.Format}
    `)){
      try{
        const res = await fetch(server+ reducer.choosenTable + '/remove', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem("SLIDESHOW-TOKEN")
            },
            body: JSON.stringify({
                _id: id
            })
        })

        const aler = await res.json();
        if(res.status === 200){
          setInfoType("Succes")
          setInfo("Udało się usunąć!")
          getData()
        }

        else if(res.status === 401 || res.status === 400){
          setInfoType("Fail")
          setInfo("Nie udało się usunąć!")
        }



      } catch(err){
            console.error(err)
  }}}

  const handleChangePage = async (queryString,num) => {
    setArr(null)

    const json = await fetch(server+ `${reducer.choosenTable}/get/${num}?` + queryString,{
      headers: {
        'x-auth-token': localStorage.getItem('SLIDESHOW-TOKEN')
      }
    })

    const docs = await json.json()
    const data = docs.docs

    setClearForm(true)
    setArr(data)

    dispatch({ type: SET_CURRECT_PAGE, item: docs.page})
    dispatch({ type: SET_ALL_PAGES, item: docs.totalPages})
} 
const getName = async () => {
  try{
  const json = await fetch(server + '/api/doppio/user/name',{
      headers: {
        'x-auth-token': localStorage.getItem('SLIDESHOW-TOKEN')
      }
    })

  const data = await json.json();
  const Name = data.name;
  const Img = data.img;
  setName(Name);
  setImg(Img)

  } catch(err){
      console.error(err);
  }
}


    useEffect(() => {
        //dispatch({type: SET_ROLES, item: {roles: localStorage.getItem('SLIDESHOW-ROLES').split(',')} })
        getData()
        getFiltersData();
        getName();
        console.log(reducer)
    },[reducer.choosenTable])
/*
    useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      //setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);


    const handleScroll = e => {
        console.log('e')
    }
*/
  return (
    <div className="App"> 
    {
      infoType &&         
      <Toast  
          infoType={infoType}
          info={info}
          setInfoType={setInfoType}
      />
    }

      <Header roles={roles} getData={getData} server={server} name={name} img={img}/>

      
        <Filters 
          getAll={getData}
          set={handleClick}
          filtersActive={reducer.filtersActive}
        />
        


    <div className="table-wrap">
      <table className="creations">

        <thead>
        {reducer.filtersData && 
        <CreationSchema  
          filtersData={reducer.filtersData}
          click={handleClick} 
          getAll={getData}
          clear = {clearForm}
          setClear = {setClearForm}
        ></CreationSchema> 
        }
        </thead>
        <tbody>

          {
          arr && arr.map(item => 
            <Creation 
              key={item._id} 
              item={item}  
              click={() => removeElement(item._id,item)}>  
            </Creation>)
            }
        </tbody>
      </table>

  

      </div>
      {
            (reducer.filtersData && (reducer.role === USER || reducer.role === ADMIN)) &&
            <Add 
              server={server} 
              getData={getData} 
              formaty={reducer.filtersData.Format} 
              categories={reducer.filtersData.Kategoria} 
              setInfoType={setInfoType} 
              infoType={infoType}
              setInfo={setInfo}
              info={info}
            />
      }
        {
            (reducer.filtersData && (reducer.role === USER || reducer.role === ADMIN)) &&
            <Edit server={server} getData={getData} formaty={reducer.filtersData.Format} categories={reducer.filtersData.Kategoria}
            setInfoType={setInfoType} 
            infoType={infoType}
            setInfo={setInfo}
            info={info}
            />
        }


            <FilterPages 
                query={createQueryString(reducer.paggingQuery)} 
                click={handleChangePage}
                docs={reducer.allPages}
                current={reducer.currentPage}
              />
      {!arr &&<h3 className="loading">Ładowanie...</h3>}
      {Array.isArray(arr) && !arr.length && <h3 className="loading">Brak rekordów zgodnych z filtrami</h3>}
    </div>
  );
}

export default App;
