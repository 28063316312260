import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router";
import { AddEditSelect } from "../Filter/Filter";
import gsap from "gsap";
import Back from "../Back/Back";
import './add.css'
import { useStateValue } from "../../stateProvider/stateProvider";

const Add = ({server, getData, formaty,categories,setInfoType,infoType,setInfo,info}) => {
    console.log(formaty)
    const navigate = useNavigate();
    const [reducer,dispatch] = useStateValue()
    const [campaign,setCampaign] = useState("");
    const [client,setClient] = useState("");
    const [format,setFormat] = useState("");
    const [cat,setCat] = useState("");
    const [link,setLink] = useState("");

    const add = useRef()

    const handleChange = (type,e) => {
        if(type === 'campaign')
            setCampaign(e.target.value)
        if(type === 'client')
            setClient(e.target.value)
        if(type === 'format')
            setFormat(e.target.value)
        if(type === 'cat')
            setCat(e.target.value)
        if(type === 'link')
            setLink(e.target.value)
    }

    const handleClick = e => {
        e.preventDefault();
        addData()
    }
    const handleClear = e => {
        //e.preventDefault();
        setCampaign("");
        setCat("")
        setClient("")
        setFormat("")
        setLink("")
    }

    async function addData(){
        if(!campaign||
        !client||
        !link)
            return alert("Nie podano wszystkich danych!")



        try{
        const res = await fetch(server + reducer.choosenTable + '/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('SLIDESHOW-TOKEN')
            },
            body: JSON.stringify({
                Kampania: campaign,
                Klient: client,
                Link: link,
                Format: format ? format : "Nie wypełnione",
                Kategoria: cat ? cat : "Nie wypełnione",
            })
        })

        const aler = await res.json();

        if(res.status === 200){
            getData()
            gsap.to(add.current,{opacity:0,duration:.3,onComplete: () => {
                add.current.style.display = "none";
                gsap.set(add.current,{opacity:1})
              }})
            handleClear()
        }
            

        setInfoType("Succes")
        setInfo("Udało się dodać!")
        } catch(err){
            setInfoType("Fail")
            setInfo("Nie udało się usunąć!")
        }
    }

    useEffect(() => {
        if(!localStorage.getItem("SLIDESHOW-TOKEN"))
        navigate('/login')
    },[])
    return (
        <div className="add" ref={add}>
            <span onClick={handleClear}>
            <Back  />
            </span>
             
        <form className="add-form">
           
            <div className="add-input-div  add-div-small">
                <label 
                className="add-label"

                htmlFor="campaign">
                    Kampania: </label>
                    
                <input type="text" 
                placeholder="Wpisz"
                name="campaign" value={campaign}                 
                onChange={e => handleChange('campaign',e)} />
            </div>
            <div className="add-input-div  add-div-small">
                <label
               
                className="add-label"
                 htmlFor="client">Klient: </label>
                 
                <input type="text" 
                 placeholder="Wpisz"
                name="client" value={client} 
                onChange={e => handleChange('client',e)} />
            </div>
            <AddEditSelect 
                val={format}
                title="Format"
                
                opts={formaty}
                set={setFormat}
            />
                <AddEditSelect 
                val={cat}
                title="Kategoria"
                defaultt={""}
                opts={categories}
                set={setCat}
            />
            <div className="add-input-div add-div-medium">
                <label 
                className="add-label"
                htmlFor="link">Link: </label>
                
                <input 
                placeholder="Wklej"
                    type="text" 
                    name="link" 
                    value={link}  
                    onChange={e => handleChange('link',e)} 
                />
            </div>
            <div className="add-button-div">
            <button 
                className="add-submit " 
                type="submit"
                onClick={handleClick}
            >Dodaj
            </button>
            
         
{
  /* 
    <button
                className="add-submit margin-left"
                type="submit"
                onClick={handleClear}
              >
                Wyczyść
              </button>
  
  */
}
            </div>
        </form>
        </div>
    )
}

export default Add;